import React, { Component } from "react";
import { Link } from "react-router-dom";

class FooterWhite extends Component {
  render() {
    return (
      <footer className="ltn__footer-area ltn__footer-2  ltn__footer-color-2">
        <div className="footer-top-area  section-bg-1">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-md-5">
                <div className="footer-widget ltn__footer-timeline-widget ltn__footer-timeline-widget-1">
                  <h6 className="ltn__secondary-color text-uppercase">
                    Meet Us
                  </h6>
                  <h4 className="footer-title">Visit Us On...</h4>
                  <ul>
                    <li>
                      Sunday <span>11:00AM - 9:00PM</span>
                    </li>
                    <li>
                      Monday <span>11:00AM - 9:00PM</span>
                    </li>
                    <li>
                      Tuesday <span>11:00AM - 4:00PM</span>
                    </li>
                    <li>
                      Wednesday <span>11:00AM - 9:00PM</span>
                    </li>
                    <li>
                      Thursday <span>11:00AM - 9:00PM</span>
                    </li>
                    <li>
                      Friday <span>11:00AM - 9:00PM</span>
                    </li>
                    <li>
                      Saturday <span>11:00AM - 9:00PM</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-5 col-md-7">
                <div className="footer-widget footer-menu-widget footer-menu-widget-2-column clearfix">
                  <h4 className="footer-title">Our Apps</h4>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="/service">User App</Link>
                      </li>
                      <li>
                        <Link to="/service">Rider App</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="footer-widget footer-blog-widget">
                  <h4 className="footer-title">News Feeds.</h4>
                  <div className="ltn__footer-blog-item">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt"/> January, 2020
                        </li>
                      </ul>
                    </div>
                    <h4 className="ltn__blog-title">
                      <Link to="/news">The Journey of Holo</Link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__copyright-area ltn__copyright-2">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="site-logo-wrap">
                  <div className="site-logo">
                    <Link to="/">
                      <img
                        src="img/Holo-Logo-Black.png"
                        alt="Logo"
                        width="192"
                        height="52"
                      />
                    </Link>
                  </div>
                  <div className="get-support ltn__copyright-design clearfix">
                    <div className="get-support-info">
                      <h6>Copyright & Design By</h6>
                      <h4>
                        Holo - <span className="current-year"></span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 align-self-center">
                <div className="ltn__copyright-menu text-right">
                  <ul>
                    <li>
                      <Link to="/terms">Terms &amp; Conditions</Link>
                    </li>

                    <li>
                      <Link to="/privacyEnglish">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterWhite;
